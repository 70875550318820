import {
  Authenticator,
  useAuthenticator,
  View,
  Button,
  CheckboxField,
} from "@aws-amplify/ui-react";
import React from "react";
import Link from "next/link";

const components = {
  SignIn: {
    Footer() {
      const { toForgotPassword } = useAuthenticator();

      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            className="text-gray-500 hover:text-gray-600"
            onClick={toForgotPassword}
            size="small"
            variation="link"
          >
            Forgot Password
          </Button>
        </View>
      );
    },
  },
  SignUp: {
    Footer() {
      const { toSignIn } = useAuthenticator();

      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            className="text-gray-500 hover:text-gray-600"
            onClick={toSignIn}
            size="small"
            variation="link"
          >
            Back to Sign In
          </Button>
        </View>
      );
    },
    FormFields() {
      const { validationErrors } = useAuthenticator();

      return (
        <>
          {/* Re-use default `Authenticator.SignUp.FormFields` */}
          <Authenticator.SignUp.FormFields />

          {/* Append & require Terms & Conditions field to sign up  */}
          <CheckboxField
            errorMessage={validationErrors.acknowledgement as string}
            hasError={!!validationErrors.acknowledgement}
            name="acknowledgement"
            value="yes"
            label={
              <>
                I agree with the{" "}
                <Link
                  className="text-blue-500 underline"
                  href="/terms&conditions"
                  target="_blank"
                  rel="noopener noreferrer"
                  passHref
                >
                  Terms & Conditions
                </Link>
              </>
            }
          />
        </>
      );
    },
  },
};
const services = {
  // eslint-disable-next-line consistent-return
  async validateCustomSignUp(formData) {
    if (!formData.acknowledgement) {
      return {
        acknowledgement: "You must agree to the Terms & Conditions",
      };
    }
  },
};

export default function LoginComponent() {
  if (typeof document !== "undefined") {
    const headingElement = document.querySelector(".amplify-heading--4");
    if (headingElement) {
      headingElement.textContent = "We Emailed You";
    }
  }

  return (
    <View>
      <div className="pt-10" />
      <Authenticator
        loginMechanisms={["email"]}
        signUpAttributes={["preferred_username"]}
        components={components}
        socialProviders={["google"]}
        // hideSignUp
        services={services}
      />
    </View>
  );
}
